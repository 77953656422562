import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';
import { useState } from 'react';


const Feedbacks = () => {
    const navigate = useNavigate();
    const { page } = useParams();
    const [searchParams] = useSearchParams();
    const [tableOptions, _] = useState<{
        rowPerPage: number;
        filter: any;
        filters?: any;
        page: number;
        order?: string;
        orderType?: 1 | 0 | -1 | undefined | null;
    }>((searchParams.get('query')
        ? JSON.parse(decodeURIComponent(searchParams.get('query') as string))
        : (sessionStorage.getItem('fb_query')
            ? JSON.parse(sessionStorage.getItem('fb_query') as string) : undefined)));

    const loadFeedbacks = async (option: tableOptions) => {
        let sorts: any = {};
        if (option.order) {
            sorts[option.order] = option.orderType;
        }
        navigate(`?query=${encodeURIComponent(JSON.stringify(option))}`);
        sessionStorage.setItem('fb_query', JSON.stringify(option));

        const feedbacks = await FeedbackService.getInstance().getListFeedbacks(
            option.page,
            option.rowPerPage,
            option.filter,
            option.filters,
            sorts

        ).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks?.data?.totalRecord ?? 0, data: feedbacks?.data?.items ?? [] }
    }

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.updated_ts;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    const handleDetailButtonClick = (rowData: any) => {
        navigate(`/feedbacks/detail/${rowData._id}`)
    };

    const handlePageChange = (page: any) => {
        navigate(`/feedbacks/${page}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={loadFeedbacks}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                        tableOption={tableOptions}
                    >
                        <Column
                            body={(rowData: any) => (
                                <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                        <Column field="updated_ts" sortable body={bodyFormattedDateTime} header="Updated Time" headerStyle={{ minWidth: '10rem' }} />
                        <Column field="ref_detail.url_v2" header="Asset" body={(rowData) => {
                            return rowData.ref_detail ? (
                                <div className="p-3">
                                    <Image src={rowData.ref_detail?.url_v2[0]} alt={'item'} width="120" preview />
                                </div>
                            ) : <></>
                        }} headerStyle={{ width: 120 }} />
                        <Column field="rating_text" sortable header="Rating" headerStyle={{ minWidth: '10rem' }} body={(rowData) => rowData.rating_text.toUpperCase()} />
                        <Column field="feedback_for" sortable header="Action" headerStyle={{ minWidth: '10rem' }} />
                        <Column field="from_app" header="From App" sortable headerStyle={{ minWidth: '10rem' }} />
                        <Column field="ref_id" header="Ref ID" sortable headerStyle={{ minWidth: '10rem' }} />
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default Feedbacks;
